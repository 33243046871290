import React from 'react'
import './Spacer.css'

function Spacer({className}) {
  return (
    <div className={className}>

    </div>
  )
}

export default Spacer
